.main-menu-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  opacity: 0.5;
  z-index: 1100;
  width: 100vw;
  height: 100vh;
}

.main-menu {
  position: fixed;
  top: 0;
  left: -500px;
  z-index: 1101;
  width: max-content;
  min-width: 300px;
  height: 100vh;
  // background-color: #fff;
  animation: showMenu 500ms forwards;
}

@keyframes showMenu {
  0% {
    opacity: 0;
    left: -500px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.main-menu-header {
  background-color: #1d2127;
  padding: 10px;
  color: #fff;
  box-shadow: 2px 5px 15px -1px grey;
}

.main-menu-back {
  position: absolute !important;
  left: 0;
  color: #fff !important;
}

.main-menu-header-items {
  color: #fff !important;
}

.main-menu-header-device:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.main-menu-header-logo {
  height: 44px;
  position: relative;
}

.main-menu-app-version {
  font-size: 10px;
}

.main-menu-header-divider {
  margin-top: 16px !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
  height: 2px;
  border: none;
  margin: 0;
}

.menu-item-btn {
  .text {
    display: flex;
    flex-direction: column;
    margin: 3px 6px;

    :nth-child(2) {
      font-size: 12px;
      opacity: 0.75;
    }
  }
}

.main-menu-bottom-logout {
  position: absolute;
  bottom: 0;
  width: 100%;

  .text {
    display: flex;
    flex-direction: column;
    margin: 3px 6px;

    :nth-child(2) {
      font-size: 12px;
      opacity: 0.75;
    }
  }
}

.main-menu-quota {
  position: absolute;
  width: 100%;
  height: 68px;

  .text {
    display: flex;
    flex-direction: column;
    margin: 3px 6px;

    :nth-child(2) {
      font-size: 12px;
      opacity: 0.75;
    }
  }
}

.main-menu-scroll-items {
  overflow-y: auto;
  position: absolute !important;
  bottom: 100px;
  top: 232px;
  width: 100%;
  padding-bottom: 0px !important;

  &.desktop {
    top: 242px !important;
  }
}
