.floating-cart {
  background-color: #fff;
  border: 1px solid;
  z-index: 1200;
  position: fixed;
  top: 75px;
  right: 0px;
  max-width: 400px;
  /* min-width: 400px; */
  box-shadow: -5px 10px 10px 0px rgba(0, 0, 0, 0.5);
  /* height: 90%; */
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  animation: showCart 300ms forwards;
}

@keyframes showCart {
  0% {
    height: 0%;
    opacity: 0;
  }
  100% {
    height: 90%;
    opacity: 1;
  }
}

.fixed-cart {
  // background-color: #fff;
  position: fixed;
  top: 64px;
  right: 0px;
  max-width: 25%;
  min-width: 25%;
  height: calc(100% - 65px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-left: 1px solid #ccc;
}

/* Whole list */
.cart-list-items {
  // background-color: #fff;
  padding: 0px !important;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
}

/* Just the item */
.cart-list-item {
  padding: 5px !important;
}

.no-items {
  position: relative;
  top: 6px;
}

.cart-item-avatar {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 5px !important;
}

.cart-item-avatar-default {
  position: relative;
  width: 50px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 5px !important;
  background: #5c5c5c;
  border-radius: 5px;
  z-index: 1;
  text-align: center;
}

.cart-item-avatar-no-img-initial {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  z-index: 2;
  margin: auto;
}

.cart-item-values {
  width: 120px;
}

.cart-item-price {
  padding: 0 !important;
}

.cart-list-no-item {
  text-align: center;
  padding-left: 50px !important;
  margin-left: -35px !important;
}

.shrink-row-height {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.updated-product {
  position: absolute;
  top: 150px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  /* display: none; */
}

@keyframes hideCart {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hide-cart {
  animation: hideCart 200ms forwards;
}

.delivery-details {
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  .print {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .row {
      width: 100%;
      flex: 1;
    }
  }
}
.delivery-details-panel {
  background-color: #f5f5f5 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.delivery-details-panel-icon {
  transform: rotate(-90deg);
}
.delivery-details-panel-header {
  padding: 0 8px !important;
  min-height: 36px !important;
  max-height: 36px !important;
}
.delivery-details-panel-details {
  padding: 8px !important;
  text-align: initial;
}

.bulk-actions-menu {
  position: absolute;
  top: 45px;
  left: 10px;
  width: calc(100% - 40px);
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 8px 2px #bbb;
  border-radius: 5px;
  padding: 10px;
}

.bulk-actions-action {
  padding: 8px !important;
  font-size: 18px !important;
}

.bulk-actions-discount-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  position: absolute;
  top: 4px;
}

.cart-name-div {
  background-color: #8c8c8c;
  color: #fff;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
}

.seat-header {
  background-color: #b6bfc4;
  height: 25px;
  padding-top: 2px;
  cursor: pointer;
}

.active-seat-header {
  background-color: #93bc39;
  height: 27px;
  padding-top: 5px;
  font-weight: bold;

}