.count-btn-component {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.count-btn-component-btn {
  flex: 2;
}

.count-btn-component-label {
  flex: 4;
  text-align: center;
  font-size: 22px;
}
