.iframe-container {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1102;
  position: fixed;
  background: rgba(123, 123, 123, 0.5);
  justify-content: center;
  align-items: center;
  margin-top: -64px;
}

.iframe {
  position: fixed;
  width: 80%;
  height: 80%;
  border: 0px;
}