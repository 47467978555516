.cashinout-wrapper {
  display: flex;
  flex-flow: column;

  .cashinout-input {
    padding: 10px 0 0 0;
    margin: 0 0 10px 0;
  }

  .fabProgress {
    color: green;
    position: absolute;
    top: -6;
    left: -6;
    z-index: 1;
  }

  .cashinout-fab-wrapper {
    text-align: left;
    margin-top: 35px;
  }

  .cashinout-fab-button {
    background-color: #318ed5;
  }

  .cashinout-group {
    display: flex;
    flex-flow: row;
    align-items: center;

    .action {
      display: flex;
      align-items: center;
    }
  }

  .cashinout-in__success {
    transform: scale(1.1);
    background-color: green;
    color: #fff !important;

    &:hover {
      background-color: green;
    }

    &:disabled {
      background-color: green;
    }
  }
}
