.Cart-Total {
  background-color: #eee;
  font-weight: 600;
  /* padding: 5px 5px; */
  flex: 0 0 auto;
}

.cart-details {
  border-top: 1px solid #444;
}

.cart-discount {
  padding: 5px;
}

.cart-icon {
  background-color: #717171;
  line-height: 60px;
  font-size: 35px;
  display: table;
  overflow: hidden;
}

.cart-icon-font {
  transform: scale(1.5);
  vertical-align: text-top;
  display: inline-table;
}

.shrink-row {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.open-discount {
  cursor: pointer;
}

.total {
  font-size: 26px;
  margin-top: 5px !important;
}

.total-btn {
  font-size: 26px !important;
  padding: 30px !important;
  background-color: #318ed5 !important;
  border-radius: 0px !important;
}

.total-btn small {
  font-size: 12px !important;
  color: #fff;
}
