.covers-takeaway-content {
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.covers-takeaway-item {
  flex: 1;
  margin: 5px;
  text-align: center;
}

.takeaway-btn {
  background-color: #318ed5 !important;
  color: #fff !important;
}

.warning-text {
  font-weight: bold;
  color: #9b353d;
  max-width: 400px !important;
}