.change {
  span {
    position: relative;
    top: 10px;
  }

  .divider {
    margin-top: 18px;
  }
}

.partial-container {
  margin-bottom: 30px;
}

.partial-left {
  position: absolute;
  left: 20px;
}

.partial-right {
  position: absolute;
  right: 20px;
}

.paid-so-far {
  margin-top: -20px !important;
  color: #817f7f;
}

.percent-btn {
  width: 8vw;
  min-width: 45px;
  max-width: 85px;
}