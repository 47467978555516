.customer-info {
  background-color: #ddd;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;

  .icon {
    // flex: 0.5;
    font-size: 24px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    padding: 4px;
    transition: border 0.5s ease-in-out;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 1);
      filter: contrast(150%);
    }
  }

  .text {
    flex: 1;
    color: rgba(0, 0, 0, 0.75);
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 5px;
    transition: color 0.5s ease-in-out;

    &:hover {
      color: #000;
      filter: contrast(150%);
    }
  }
}
