.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 120px);
  grid-column-gap: 10px;
  justify-content: center;
  margin-top: 3px;
}

.increase-product-size {
  grid-template-columns: repeat(auto-fit, 190px) !important;
}

.products-not-found {
  border: 1px solid;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);

  span {
    font-weight: 400;
  }
}

.loader-container {
  .loader {
    display: inline-block;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #318ed5;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: loader-spin 1.2s linear infinite;
  }
  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
