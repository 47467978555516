.catalog-header {
  position: fixed;
  width: 75%;
  height: 61px;
  top: 64px;
  z-index: 1000;
  background-color: #ddd;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  display: flex;
  align-items: center;

  button {
    height: 50px;
  }
}

.product-search-bar-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 55px;
}

.product-search-bar {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #aaa;
  border-radius: 5px;
  width: 86%;
  height: 50px;

  input {
    border-bottom: 0px !important;
  }
}

.product-search-bar-icon {
  margin: 6px;
}

.product-search-bar-icon-close {
  margin: 6px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
}
