.modal-mergin-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;

  svg {
    color: #318ed5;
  }
}

.table-layout-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .table-merge-container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .cancel-merge-btn {
      background-color: #e26a6a !important;
    }

    .start-merge-btn {
      background-color: #318ed5;
    }

    button {
      color: #ffffff;
    }
  }

  .change-floorplan {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      small {
        font-size: 12px;
      }
    }
  }
}

.floorplan {
  position: relative;
  width: 100%;
  height: calc(100% - 10px);
  border: 3px solid;
  overflow: hidden;
}

.merge-tables-bgr {
  background-color: rgb(155, 155, 155);
}

.static {
  position: absolute;
  z-index: 100;
  background-color: #444;
}

.item {
  position: absolute;
  z-index: 100;
}

.table {
  position: absolute;
  background-color: #ffffff;
  // box-shadow: 2px 2px 2px #aaa;
  font-weight: 600;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms;

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;

    span {
      font-size: 12px;
      font-weight: 200;

      &.small {
        font-size: 12px;
      }
    }
  }
  .details-bar,
  .details-bar-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    position: absolute;
    margin-top: 10px;
    // left: 10%;

    span {
      font-size: 12px;
      font-weight: 200;

      &.small {
        font-size: 12px;
      }
      &.small-right {
        font-size: 12px;
        margin-right: 50px;
      }
      &.small-left {
        font-size: 12px;
        margin-left: 50px;
      }
    }
  }
  .details-bar-info {
    left: 0;
    width: 90%;
  }
  .details-bar-info span {
    width: 60%;
  }

  .bar-name {
    align-items: center;
    text-align: center;
    position: absolute;
    top: 25%;
  }

  &:hover {
    transform: scale(1.02);
  }
}
.icon-div {
  margin-bottom: 5px;
  text-align: center;
}
.icon-text {
  font-family: sans-serif !important;
  font-size: 16px;
  font-weight: bold !important;
}
.covers-button {
  background-color: #318ed5 !important;
  color: #f0f0f0 !important;
  font-weight: bold !important;
  float: right;
  text-transform: none !important;
  border-radius: 7px !important;
}
