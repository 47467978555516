.main {
  display: flex;
}

.main-left {
  width: 75%;
  z-index: 1;
}

.main-right {
  width: 25%;
  z-index: 2;
}

.arrow-up {
  position: fixed;
  top: 58px;
  right: 5px;
  z-index: 1201;
  width: 0px;
  height: 0px;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 20px solid #ddd;
}
.arrow-up-border {
  position: fixed;
  top: 56px;
  right: 5px;
  z-index: 1199;
  width: 0px;
  height: 1px;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 20px solid #1d2127;
}

.cart-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  opacity: 0.5;
  z-index: 1100;
}

.barcode-scanner {
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -100;
}

/* Loader bar */
.load-bar {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: #fdba2c;
}
.bar {
  content: '';
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}
.bar:nth-child(1) {
  background-color: #da4733;
  animation: loading 3s linear infinite;
}
.bar:nth-child(2) {
  background-color: #3b78e7;
  animation: loading 3s linear 1s infinite;
}
.bar:nth-child(3) {
  background-color: #fdba2c;
  animation: loading 3s linear 2s infinite;
}

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}

.helper_message {
  font-size: 0.8em;
  font-style: italic;
  color: red;
}

.kdsIframed {
  z-index: 1100;
  top: 0px;
  left: 0px;
  border: none;
  width: 100%;
  height: 100%;
}
