.cart-header {
  background-color: #ddd;
  border-bottom: 1px solid #ccc;
  min-height: 61px !important;
  max-height: 61px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cart-header-name {
  flex: 1;
}

.btn-layout {
  margin: auto;
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: uppercase;
}

.selection-cart-list {
  padding: 0 !important;
}

.selection-cart-new {
  background-color: #318ed5 !important;
  color: #fff !important;
}
.selection-cart-new:hover {
  transform: scale(1.02);
  box-shadow: 0 2px 8px 2px #bbb;
}

.selection-cart-new-text {
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding-right: 0 !important;
}

.selection-cart-delete {
  background-color: #f50057 !important;
  color: #fff !important;
}
.selection-cart-delete:hover {
  transform: scale(1.02);
  box-shadow: 0 2px 8px 2px #bbb;
}
