.calculator {
  border-top: 1px solid #cccccc;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  padding: 0 3px;
}

.calculator-result-inner {
  margin-top: 3px;
  width: 90%;
  border: 1px solid;
  border-radius: 5px;
  align-self: center;
}
