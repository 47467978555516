.login {
  width: 300px !important;
  position: relative;
  margin: 16px auto !important;
}

.login-input-field {
  width: 300px;
}

.login-input-field-label {
  text-align: left;
}

.login-btn {
  width: 300px;
}

.middle {
  text-align: left !important;
}

.remember-login-div {
  position: relative;
  right: 20px;
}

.remember-login {
  position: relative;
  top: 2px;
  cursor: pointer;
}

label.remember-password {
  position: relative !important;
  top: 0 !important;
}
