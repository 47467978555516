.cart-entry {
  background-color: #fff;
  padding: 0px !important;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
}

.cart-entry-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.5em;
  padding: 5px;
  border-left: 4px;

  &.NEW {
    border-left-style: solid;
    border-color: #aaa;
  }

  &.PENDING,
  &.PRINTED {
    border-left-style: solid;
    border-color: #ffd65c;
  }
  &.PREPARING {
    border-left-style: solid;
    border-color: #f79d34;
  }
  &.READY {
    border-left-style: solid;
    border-color: #3bae70;
  }
  &.COMPLETED {
    border-left-style: solid;
    border-color: #64c2ea;
  }
}

.cart-entry-avatar {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.cart-entry-avatar-default {
  position: relative;
  min-width: 40px;
  line-height: 40px;
  background: #5c5c5c;
  z-index: 1;
  text-align: center;

  .initial {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    z-index: 2;
    margin: auto;
  }
}

.cart-entry-details {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .product-price {
    font-size: 14px;
    opacity: 0.5;
  }

  .extras {
    font-size: 14px;
    opacity: 0.8;
  }

  .discount {
    text-decoration: line-through;
  }

  .square_status {
    display: flex;
    flex-direction: row;

    i {
      padding: 1px;
      font-size: 0.7em;
      max-width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      font-style: normal;
      font-family: monospace;
    }

    .new_square {
      background-color: #aaa;
    }

    .printed_square {
      background-color: #ffd65c;
    }

    .ready_square {
      background-color: #3bae70;
    }

    .preparing_square {
      background-color: #f79d34;
    }

    .completed_square {
      background-color: #64c2ea;
    }
  }
}

.delete-btn {
  right: 5px !important;
}
