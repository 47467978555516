.catalog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .categories-list-section {
    flex: 2;
    height: calc(100vh - 128px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 64px;
    scrollbar-color: rgba(0, 0, 0, 0.5) #eee; // Firefox scrollbar
  }

  .products-list-section {
    flex: 8;
    height: calc(100vh - 128px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 64px;
    padding-right: 15px;
    scrollbar-color: rgba(0, 0, 0, 0.5) #eee; // Firefox scrollbar
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 15px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
}

.product-list-categories-radio-btn {
  color: #318ed5 !important;
}

.addon-group-collapse {
  padding: 11px 24px;
}
