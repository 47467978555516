.invoice-div {
  margin: 0;
  padding: 0;
}

.invoice-section {
  margin: 0 auto;
  max-width: 600px;
}

.invoice-table {
  font-size: 0px;
  width: 100%;
  border-collapse: collapse;
}

.invoice-tbody-td {
  text-align: center;
  vertical-align: top;
  font-size: 0px;
  padding: 20px 0px;
  border-collapse: collapse;
}

.invoice-table-inner {
  color: #000;
  font-family: 'Ubuntu,Helvetica,Arial,sans-serif';
  font-size: 13px;
  line-height: 22px;
  table-layout: auto;
  border-collapse: collapse;
}

.invoice-tbody-td-inner {
  vertical-align: top;
  text-align: left;
  border-collapse: collapse;
}

.invoice-td-p {
  margin-top: 0 !important;
  display: block;
  margin: 13px 0;
}

.b-b {
  border-bottom: 1px solid #dde1e2;
}
