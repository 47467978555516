.product-card {
  width: 120px;
  height: 120px;
  cursor: pointer;
  overflow: hidden;
  padding: 1px 1px 0 1px;
  margin: 5px;
  transition: box-shadow 200ms, transform 200ms;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.product-card:hover {
  box-shadow: 0 2px 8px 2px #bbb;
}
.product-card:active {
  transform: scale(0.98);
}

.product-card-simple {
  width: 120px;
  height: 120px;
  cursor: pointer;
  overflow: hidden;
  padding: 1px 1px 0 1px;
}
.product-card-simple:active {
  opacity: 0.9;
}

.product-card-out-of-stock {
  opacity: 0.6;
}

.product-card-have-stock {
  opacity: 1 !important;
}

.product-card-img {
  object-fit: cover;
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 4px;
}

.product-card-no-img-default {
  width: 120px;
  min-width: 120px;
  height: 124px;
  line-height: 120px;
  background: #5c5c5c;
  z-index: 1;
  text-align: center;
  border-radius: 4px;
}

.product-card-initial {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  z-index: 2;
  margin: auto;
  position: relative;
  bottom: 5px;
}

.product-card-content-price {
  position: relative;
  bottom: 122px;
  width: 60px;
  height: 16px;
  line-height: 16px;
  margin: auto;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
  font-size: 12px;
  color: #000;
}

.product-card-content-price-typo {
  font-size: 12px !important;
}

.product-card-content-name {
  position: relative;
  bottom: 60px;
  width: 120px;
  height: 40px;
  margin: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.product-card-content-name-typo {
  margin: auto !important;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  line-height: 20px;

  span.name {
    align-self: flex-start;
  }
}

.product-card-title {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 15px;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
}

.product-card-clone {
  position: absolute;
  top: -200px;
  right: 108px;
  z-index: 1000;
  opacity: 0;
}

.product-tile-counter {
  position: relative;
  z-index: 1;
  width: 100%;
}

.product-tile-number {
  position: absolute;
  height: 80px;
  width: 25px;
  left: -1px;
  text-align: center;
}

.product-tile-count {
  height: 14px;
  line-height: 15px;
  margin-top: -1px;
  background-color: #fff;
  font-size: 12px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
  color: #000;
}

.product-card-addons {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 0;
  line-height: 0;
}

.product-card-addons-position {
  position: absolute;
  right: 0;
  text-align: center;
}

.product-card-addons-icon {
  font-size: 16px !important;
  color: #fff;

  .icon-weight:before {
    color: #fff;
  }
}

.product-card-price-info {
  font-weight: 600;
}

.addons-dialog-content {
  min-width: 300px;
}

.addons-name {
  padding: 5px !important;
  margin-top: 5% !important;
}

.addons-btn-div {
  padding: 5px !important;
  text-align: right;
}

.addons-btn-div-count {
  padding: 0 15px;
}

.addon-btn {
  color: #318ed5 !important;
  /* text-align: right !important; */
}

.addon-btn-disabled {
  color: #b0c6d7 !important;
}

.deposit-tag {
  position: relative;
  bottom: 125px;
  font-weight: 800;
  color: #ff0000;
  font-size: 24px;
}
