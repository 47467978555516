.giftcard-details {
  width: 400px;
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .low-giftcard {
    font-size: 14px;
    color: #9b353d !important;
  }

  .title {
    margin: 4px 0;
    text-align: center;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .current-balance {
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .value {
      .current {
        font-size: 24px;
        font-weight: bold;
      }

      .total {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .progress {
      width: 100%;
      margin-bottom: 8px;

      .MuiLinearProgress-barColorPrimary {
        background-color: #3eca3e;
      }
    }

    .label {
      font-size: 16px;
      font-weight: 200;
    }
  }

  .add-balance {
    width: 100%;
  }

  .activity {
    margin: 12px 0;
    width: 100%;

    .title {
      margin: 4px 0;
      font-size: 16px;
      font-weight: 200;
      border-bottom: 0px;
    }
  }

  .date {
    margin-right: 8px;
  }

  .store {
    flex: 1;
  }

  .value {
    margin-left: 8px;
    float: right;
  }

  .list-container {
    overflow-y: auto;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    max-height: 200px;
  }
}

.action {
  padding: 8px;
  display: flex;
  justify-content: flex-end;
}
