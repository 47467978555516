.keyboard-generic {
  margin: 3px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.1em;

  .confirm {
    text-transform: uppercase;
  }
}
