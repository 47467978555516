@media print {
  * {
    box-shadow: none !important;
    text-shadow: none !important;
    background: transparent !important;
    color: #000 !important;
    -webkit-print-color-adjust: exact;
  }
}

.print-btn {
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  background: transparent;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.54);
  }
}
