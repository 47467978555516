.payplaza-print {
  position: absolute !important;
  right: 10px;
  top: 15px;
}

.payplaza-result {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 800;
}

.payplaza-print-content {
  white-space: pre-line;
  margin-top: 15px !important;
  margin-left: 15px !important;
  text-align: center;
}

.payplaza-content {
  min-width: 300px;
}

.payment-button {
  background-color: #318ed5 !important;
  color: #fff !important;
}

.payment-title {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 800;
}

.payment-info {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
}

.payment-img {
  margin: 16px;
}
