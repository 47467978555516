.payment-modal {
  min-width: 300px;
  text-align: center;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.payment-grid {
  margin: 10px 0px !important;
}

.payment-total {
  max-width: 75% !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.payment-total-value {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.payment-suggestion-label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 0.75rem;
  line-height: 1;
}

.payment-suggestion-button {
  color: #318ed5 !important;
}

.payment-selection-embedded {
  margin: 30px 0px;
}

.payment-types-grid {
  padding: 10px 12px !important;
  max-width: 48% !important;
}

.payment-types-button {
  background-color: #318ed5 !important;
  color: #fff !important;
}

.payment-types-button-disabled {
  background-color: #c3c3c3 !important;
  color: #fff !important;

}

.read-only-cursor {
  cursor: not-allowed;
}
.read-only-cursor input {
  cursor: not-allowed;
}

.payment-grid-type {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  padding: 10px;
}

.payment-grid-left {
  text-align: left;
}

.payment-grid-center {
  text-align: center;
}

.payment-grid-right {
  text-align: right;
}

.partial-payment-item {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.partial-payment-item-time {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.partial-payment-item-value {
  text-align: right;
}

.partial-payment-item-delete {
  font-size: 12px !important;
}

.partial-payment-per-item-container {
  padding-top: 0px;
  padding-bottom: 0px;
}

.add-remove-button {
  color: #318ed5 !important;
  font-size: 12px !important;
  min-width: 36px !important;
  min-height: 24px !important;
  padding: 6px !important;
}

.divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.payment-btn-fit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.spaced {
  display: flex;
}

.spaced .info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  width: 100%;
  text-align: center;
}

.spaced .heighlight {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
}

.pay-later-info {
  text-align: right;

  span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
}

.payment-btn-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 70%;
  margin: 16px 0;
}

.partial-payment-items {
  color: #817b7b;
  font-size: 15px;
}

.table-cart-name {
  background-color: #8c8c8c;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
}

.table-cart-name-payments {
  background-color: #fff;
  color: #8c8c8c;
  font-weight: bold;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
}

.active-seat {
  background-color: #bebebe;
  color: #f0f0f0;
}

.excess-modal {
  .container-style {
    display: flex;
    padding: 5px;

    .left-column {
      float: left;
      flex: 1;
    }
    .right-column {
      float: right;
    }
  }

  .gratuity-actions {
    margin-top: 20px;
    display: flex;
    gap: 55px;
  }

  .gratuity-header {
    margin-top: 10px;
    font-weight: bold;
  }
}