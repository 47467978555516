.receipt-container {
  min-width: 65%;
  margin: 15px 0;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.receipt-logo {
  width: 150px;
}

.receipt-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  max-width: 85% !important;
  line-height: 1px;
  margin: 5px 0 !important;
}

.receipt-grid-container {
  max-width: 85% !important;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.receipt-small-subitem {
  font-size: 12px;
  opacity: 0.8;
  margin-left: 15px;
}

.top {
  margin-top: 15px !important;
}

.font-14 {
  font-size: 14px !important;
}

.receipt-grid-item {
  margin: 5px 0;
}

.item-variant {
  line-height: 19px;
  vertical-align: middle;
}
