.refund-content-dialog {
  text-align: center;
  padding: 0 !important;
}

.refund-title {
  margin: 20px 0 !important;
  text-align: center;
}

.refund-total {
  border-top: 1px solid rgba(0, 0, 0, 0.42);
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  max-width: 50% !important;
  text-align: center;
}

.refund-items {
  margin-top: 20px !important;
  text-align: center;
}

.refund-list-item {
  text-align: center;
}

.refund-list-item-avatar {
  display: inline-flex;
  align-items: center;
}

.push-right {
  position: relative !important;
  left: 20px;
}

.refund-amount input {
  text-align: center;
}

.refund-all {
  text-align: center;
  margin: 15px 0px !important;
}

.refund-note {
  margin-top: 15px !important;
}
