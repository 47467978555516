.modal-content {
  min-width: 400px;
}

.addons-list-component {
  .addon {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 400px;
    margin: 5px;

    .name {
      flex: 2;
    }

    .edit {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span.current {
        font-weight: 600;
      }
    }
  }
}

.addon-groups {
  display: flex;
  flex-direction: column;

  .group {
    margin: 12px;

    .header {
      min-width: 300px;
      background-color: rgba(0, 0, 0, 0.2);
      font-size: 16px;
      font-weight: bold;
      padding: 6px 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .title {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .min-max {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  .checked {
    font-size: 22px;
    margin-right: 15px;
  }

  .valid {
    color: #1ac525;
  }

  .invalid {
    color: #d21212;
  }
}

button.plus-minus {
  background-color: #fff;
  border: 1px solid rgba($color: #000000, $alpha: 0);
  border-radius: 5px;
  outline: none;
  font-size: 18px;
  display: flex;
  transition: border 300ms ease, background-color 300ms ease;
  cursor: pointer;
}

button.plus-minus:hover {
  border: 1px solid rgba($color: #000000, $alpha: 0.5);
}

button.plus-minus:active {
  background-color: rgba($color: #000000, $alpha: 0.3);
}


.external-modal {
  text-align: center !important;
  .barcode-spinner {
    color: #338ed5 !important;
  }
}
