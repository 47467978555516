.registration-input-field {
  width: 300px;
}

.registration-input-field-label {
  text-align: left;
}

.registration-btn {
  margin-top: 15px !important;
  width: 300px;
}

.registration-select {
  width: 300px;
  text-align: left;
}

.registration-select-label {
  position: relative;
  left: -120px;
  font-size: 12px !important;
  width: 300px;
  text-align: left;
}
