.cart-actions-header {
  background-color: #ddd;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.action-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;

  .icon {
    flex: 6;
  }

  .text {
    flex: 1;
    font-size: 6px;
    white-space: nowrap;
    font-family: system-ui !important;
  }
}

.reps {
  position: relative;
  bottom: 8px;
}

.giveaway-list {
  min-width: 250px;
}
