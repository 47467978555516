/* Employees Dialog */
.employees-modal {
  text-align: center;
  max-height: 390px;
  overflow: scroll;
}

.employee-modal-pin {
  margin-top: 20px !important;
}

.pin-btn {
  background-color: #318ed5 !important;
  color: #fff !important;
}

/* Employee Tile */
.employee-tile {
  padding: 5px;
  margin: auto;
}

.employee-card {
  width: 120px;
  height: 120px;
  cursor: pointer;
  overflow: hidden;
}

.employee-card:hover {
  box-shadow: 0 2px 8px 2px #bbb;
}

.employee-selected {
  border: 2px solid #318ed5;
}

.employee-type-account {
  position: absolute;
  color: #fff;
}

.employee-card-no-img {
  width: 120px;
  min-width: 120px;
  height: 124px;
  line-height: 120px;
  background: #5c5c5c;
  z-index: 1;
  text-align: center;
}

.employee-card-initial {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  z-index: 2;
  margin: auto;
  position: relative;
  bottom: 5px;
}

.employee-card-content-name {
  position: relative;
  bottom: 38px;
  background-color: #fff;
  width: 120px;
  height: 35px;
  line-height: 35px;
  padding: 0 !important;
  margin: auto;
  overflow: hidden;
  text-align: center;
}

.employee-card-content-name-typo {
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  padding: 0 5px;
}

.dialog-input-label {
  display: block;
  margin-bottom: 15px;
}

.dialog-input-label ~ div {
  margin-bottom: 15px;
  width: 100%;
}

.shift-form-styles form input {
  display: block;
  height: 50px;
  margin-top: 3px;
}

.employee-shift-dialog-content {
  width: 400px;
}

.employee-shift-cash-wrapper {
  margin: 20px 0 20px 0;
}

.employee-shift-cash-list {
  display: flex;
  flex-flow: row wrap;
  max-width: 100%;
}

.employee-shift-cash-list-input {
  width: 32%;
  border: solid 1px #bbb;
  border-bottom: none;
  font-size: 0.9em;
}

.employee-shift-cash-list-input input {
  padding: 0;
  height: 50px;
}

.employee-shift-cash-list-input-span span {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #5c5c5c !important;
  font-size: 0.8em;
  width: 45px;
  margin: 0;
  height: 50px;
  color: #fff;
}

.employee-shift-tools {
  padding: 10px 10px 10px 0;
  text-align: right;
}

.form-shift-title {
  text-align: center;
  text-transform: uppercase;
}
