.cart-sub-total {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #444;
  background-color: #eee;

  .icon {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #717171;
    padding: 0px 8px;

    span {
      font-size: 45px;
    }
  }

  .details {
    flex: 3;
    margin-left: 60px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .item-line {
      display: flex;
      flex-direction: row;
      margin: 2px;
      font-size: 16px;
      color: #444;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.87);
      font-size: 0.875rem;
      font-weight: 500;

      .title {
        flex: 1;
        text-overflow: ellipsis !important;
        white-space: nowrap;
        overflow: hidden;
        text-transform: uppercase;
      }

      .value {
        flex: 1.5;
        white-space: nowrap;
        text-align: right;
      }
    }
  }
}
