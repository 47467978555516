.orientation-osprinter {
  position: relative;
  display: flex;
  flex-direction: row;
}

.extra-osprintersettings > div {
  display: flex;
  align-items: center;
}

.orientation-osprinter label {
  position: relative !important;
}

.printer-item {
  margin: 8px 0px !important;
}

.truned {
  transform: rotate(90deg);
}

.select-label {
  font-size: 12px !important;
  position: relative !important;
}

.select-input {
  width: 100% !important;
}

.small-row {
  height: 50px;
  text-align: right;
}

.overlap-btn {
  z-index: 10;
  position: relative;
  top: 10px;
}

.actions-right {
  justify-content: flex-end;
}

.usb-grid {
  display: flex;
  align-items: center;
}

.usb-checkbox {
  font-weight: bold;
}

.line {
  padding: 6px 32px !important;
}
