.settings-switch-grid {
  text-align: right;
}

.settings-switch {
  margin-top: 15px;
}

.settings-select-grid {
  text-align: right;
}

.settings-select {
  margin-top: 15px;
}

.reset-button-grid {
  min-height: 50px;
}

.reset-button {
  background-color: #318ed5 !important;
  color: #fff !important;
}

.description {
  cursor: help;
}

.settings-dialog {
  display: flex;
  height: 75vh;
}

.tab-group {
  align-content: space-around;
  flex: auto;
  max-width: 140px;
  position: absolute;
}

.items-group {
  flex: auto;
  margin-left: 150px;
}

.store-list {
  max-height: 300px;
}

.loading-container {
  margin-top: 50px;
}

.Mui-selected {
  color: #318ed5 !important;
}