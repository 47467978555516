.modal-list {
  width: 100%;
  min-width: 330px;
  max-width: 450px;
  position: relative;
  max-height: 300px;
}

.list-section {
  background-color: inherit;
}

.sub-header {
  font-size: 18px !important;
  font-weight: bold !important;
}

.list-items {
  background-color: inherit;
  padding: 0px !important;
  text-align: left;
}

.loader {
  text-align: center !important;
}

.title-div {
  text-align: center !important;
  background-color: #d3d3d3 !important;
  font-size: 20px !important;
  font-weight: bold !important;
  justify-content: center !important;
  height: 30px !important;
}

.sub-text {
  text-align: center !important;
  font-size: 18px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.change-button {
  position: absolute !important;
  right: 10px !important;
  margin-top: 3px !important;
  height: 24px !important;
  justify-content: center !important;
  font-size: 14px !important;
  padding: 5px;
}

.description-buttons {
  padding: 5px;
  font-size: 16px !important;
}

.modal-margin-top {
  margin-top: 10px;
}

.modal-margin-bottom {
  margin-bottom: 10px;
}

.description-input {
  border: 3px solid #686767 !important;
  border-radius: 7px;
  height: 60px;
}
