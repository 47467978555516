.transaction-title {
  display: inline-flex;
}

.transaction-btn-action {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
}

.transaction-content {
  min-width: 400px;
}

.last-transaction-result {
  margin: 15px 0 !important;
  position: relative;
  top: 10px;
}

.back-to-list-btn {
  position: relative;
  top: -10px;
  left: -15px;
}

.transactions-list {
  min-width: 300px;
}

.refund-item {
  color: #df1111 !important;
}

.refund-chip {
  position: absolute;
  right: 50px;
}

.refund-content {
  flex: 1 1 auto;
  min-width: 0px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
}

.refund-content-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  margin: 0px;
}

.refund-content-description {
  font-size: 12px;
  font-weight: 200;
  line-height: 1.5em;
  margin: 0px;
}

.transaction-paid {
  border-top: 1px solid rgba(0, 0, 0, 0.42);
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.center-items {
  text-align: -webkit-center;
  /* margin-top: 20px; */
}

.sales-info {
  max-width: 70%;
}

.transaction-subitem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  width: 75%;
}

.transaction-subitem-left {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  width: 75%;
  text-align: left;
}

.transaction-subitem-right {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  width: 75%;
  text-align: right;
}

.transaction-items {
  margin-top: 20px;
}

.purchased-items-price {
  text-align: right;
  padding-right: 0 !important;
}

.transaction-email-popover {
  padding: 15px;
}

.transaction-email-popover-title {
  margin-bottom: 15px !important;
}

.transaction-email-popover-btn {
  position: relative;
  top: 12px;
  left: 5px;
}

.transaction-has-refunds {
  position: absolute;
  left: 200px;
  top: 35px;
  color: #df1111;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.5em;
  transform: scaleX(0.92);
}

.uppercase {
  text-transform: capitalize;
}

.card-print {
  position: relative;
  right: 7px;
  padding: 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.card-print-info {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.tax-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.border {
  margin-top: 15px !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.sync-transactions {
  text-align: center;
}

.confirm-btn {
  background-color: #318ed5 !important;
  color: #fff !important;
}

.m-r-15 {
  margin-right: 15px;
}

.tip-btn-container {
  width: 100%;
}