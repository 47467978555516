.categories-list-grid-item {
  max-width: 100%;
  padding: 8px 5px;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
  box-sizing: border-box;
}

.categories-list-item-btn {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  text-overflow: ellipsis !important;
  white-space: normal !important;
  overflow: hidden !important;
}
.categories-list-item-btn:hover {
  filter: contrast(150%);
}

.categories-list-item-btn-label {
  text-overflow: ellipsis !important;
  white-space: normal !important;
  overflow: hidden !important;
}

.category-selected {
  border: 1px solid #333 !important;
  border-radius: 5px !important;
  filter: contrast(150%);
}

.category-has-child {
  position: absolute;
  right: 0;
}

.child-category {
  width: 90% !important;
}
