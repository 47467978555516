.back-button {
  padding: 8px;
  display: 'flex';
  justify-content: 'flex-end';
  background-color: '#f3f3f37e';
}

.selection-container {
  height: 230px;
}

.selection-manual-input {
  width: 450px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
}

.selection-header {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}

.search-code-textfield {
  margin: 10px;
  width: 90%;
}

.scanner-width {
  width: 280px;
}

.scanner-container {
  height: 130px;
}

.icon-button-container {
  background-color: #338ed5;
  border-radius: 50px;
  padding: 10px;
  margin-top: -5px;
}

.icon-button-container-disabled {
  background-color: #acafb3;
  border-radius: 50px;
  padding: 10px;
  margin-top: -5px;
}

.manual-input {
  margin-bottom: 20px;
}

.bar-color {
  color: #338ed5;
}
