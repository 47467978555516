.reports-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reports-content {
  min-width: 500px;
  /* text-align: center; */
}

.reports-tabs {
  margin-bottom: 35px;
}

.report-selection {
  text-align: center;
  margin: 0px 0px 15px 0px;
}

.reports-type-container-not-found {
  text-align: center;
  margin-top: 30px !important;
}

.report-selection-button {
  border: 1px solid !important;
  margin-top: 10px !important;
  display: inline-block !important;
  min-width: 120px !important;
}
.report-selection-button:hover {
  box-shadow: 2px 2px 2px 0px #ccc;
}

.date {
  font-size: 12px;
}

.reports-type-title {
  margin-top: 15px !important;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.reports-type-table {
  margin-top: 15px !important;
}

.reports-type-table-content-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  text-transform: capitalize;
  padding: 5px 2px;
}

.reports-print {
  float: right;
  position: relative;
  bottom: 10px;
}

.reports-print-content {
  margin: 15px;
}
