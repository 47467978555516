body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.button-primary-color {
  background-color: #318ed5 !important;
  color: #ffffff !important;
}

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?fa3f3v');
  src: url('fonts/icomoon.eot?fa3f3v#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?fa3f3v') format('truetype'),
    url('fonts/icomoon.woff?fa3f3v') format('woff'),
    url('fonts/icomoon.svg?fa3f3v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ticket:before {
  content: "\e92e";
}
.icon-recycle-512:before {
  content: '\e924';
}
.icon-takeaway:before {
  content: '\e922';
}
.icon-dropship:before {
  content: '\e923';
}
.icon-easy-cash:before {
  content: '\e921';
}
.icon-breakage:before {
  content: '\e920';
}
.icon-ccv:before {
  content: '\e91f';
}
.icon-sunbed:before {
  content: '\e91e';
}
.icon-handpoint:before {
  content: '\e916';
}
.icon-payconiq:before {
  content: '\e917';
}
.icon-payleven:before {
  content: '\e918';
}
.icon-payment-sense:before {
  content: '\e919';
}
.icon-payplaza:before {
  content: '\e91a';
}
.icon-payworks:before {
  content: '\e91b';
}
.icon-stripe:before {
  content: '\e91c';
}
.icon-worldline:before {
  content: '\e91d';
}
.icon-close_shift:before {
  content: '\e914';
}
.icon-open_shift:before {
  content: '\e915';
}
.icon-change_employee:before {
  content: '\e911';
  color: #34495e;
}
.icon-change_store:before {
  content: '\e912';
  color: #34495e;
}
.icon-data_analysis:before {
  content: '\e913';
  color: #34495e;
}
.icon-country:before {
  content: '\e910';
  color: #34495e;
}
.icon-yodopay:before {
  content: '\e90f';
  color: #34495e;
}
.icon-bunq:before {
  content: '\e90e';
}
.icon-paper-check:before {
  content: '\e90d';
  color: #34495e;
}
.icon-cash-in:before {
  content: '\e90b';
  color: #34495e;
}
.icon-cash-out:before {
  content: '\e90c';
  color: #34495e;
}
.icon-tips:before {
  content: '\e90a';
  color: #34495e;
}
.icon-coupon:before {
  content: '\e909';
  color: #34495e;
}
.icon-left-double-arrow:before {
  content: '\e907';
  color: #34495e;
}
.icon-right-double-arrow:before {
  content: '\e908';
  color: #34495e;
}
.icon-card:before {
  content: '\e905';
  color: #34495e;
}
.icon-cash:before {
  content: '\e906';
}
.icon-arrow:before {
  content: '\e904';
  color: #52b3d9;
}
.icon-billing-info-sidebar2:before {
  content: '\e903';
  color: #35495e;
}
.icon-reoder:before {
  content: '\e900';
}
.icon-tax-sidebar:before {
  content: '\e901';
}
.icon-categories-sidebar:before {
  content: '\e902';
}
.icon-pay-later-refund:before {
  content: '\e6ae';
}
.icon-pay-later:before {
  content: '\e6af';
}
.icon-izettle:before {
  content: '\e692';
}
.icon-seqr:before {
  content: '\e6b2';
}
.icon-bold-font:before {
  content: '\e6b3';
}
.icon-adyen:before {
  content: '\e6ad';
}
.icon-sumup:before {
  content: '\e6b0';
}
.icon-stock-management:before {
  content: '\e6b1';
}
.icon-amex:before {
  content: '\e6a3';
}
.icon-bank-transfer:before {
  content: '\e6a4';
}
.icon-belfius:before {
  content: '\e6a5';
}
.icon-ideal:before {
  content: '\e6a6';
}
.icon-mastercard:before {
  content: '\e6a7';
}
.icon-mister-cash:before {
  content: '\e6a8';
}
.icon-paypal:before {
  content: '\e6a9';
  color: #666;
}
.icon-paysafe:before {
  content: '\e6aa';
}
.icon-sofort:before {
  content: '\e6ab';
}
.icon-visa:before {
  content: '\e6ac';
}
.icon-phone-support:before {
  content: '\e6a1';
}
.icon-email-receipt:before {
  content: '\e6a2';
}
.icon-close-toast:before {
  content: '\e69d';
}
.icon-dashboard-overview:before {
  content: '\e69e';
}
.icon-help:before {
  content: '\e69f';
}
.icon-home:before {
  content: '\e6a0';
}
.icon-error_bold:before {
  content: '\e69c';
}
.icon-not-visible:before {
  content: '\e69a';
}
.icon-visible:before {
  content: '\e69b';
}
.icon-minus-border:before {
  content: '\e698';
}
.icon-plus-border:before {
  content: '\e699';
}
.icon-image-add:before {
  content: '\e691';
}
.icon-font-size:before {
  content: '\e693';
}
.icon-italic-font:before {
  content: '\e694';
}
.icon-text-center:before {
  content: '\e695';
}
.icon-text-left:before {
  content: '\e696';
}
.icon-text-right:before {
  content: '\e697';
}
.icon-edit:before {
  content: '\e690';
}
.icon-enter:before {
  content: '\e681';
}
.icon-bitcoin-thin:before {
  content: '\e687';
}
.icon-card-thin:before {
  content: '\e688';
}
.icon-cash-thin:before {
  content: '\e689';
}
.icon-gift-thin:before {
  content: '\e68a';
}
.icon-hybrid-payment-thin:before {
  content: '\e68b';
}
.icon-invoice-thin:before {
  content: '\e68c';
}
.icon-mobile-payment-thin:before {
  content: '\e68d';
}
.icon-note-thin:before {
  content: '\e64d';
}
.icon-product2:before {
  content: '\e68e';
}
.icon-addproduct:before {
  content: '\e68f';
}
.icon-clock:before {
  content: '\e686';
}
.icon-hamburguer-small:before {
  content: '\e685';
  color: #444;
}
.icon-printer-paired:before {
  content: '\e684';
  color: #444;
}
.icon-add-ons:before {
  content: '\e683';
  color: #444;
}
.icon-gift-bold:before {
  content: '\e680';
  color: #444;
}
.icon-hybrid-payment:before {
  content: '\e682';
  color: #444;
}
.icon-world:before {
  content: '\e67f';
  color: #444;
}
.icon-bitcoin:before {
  content: '\e67e';
  color: #444;
}
.icon-weight:before {
  content: '\e67d';
  color: #444;
}
.icon-business-category:before {
  content: '\e67c';
  color: #444;
}
.icon-loading-extra-large:before {
  content: '\e678';
  color: #444;
}
.icon-loading-large:before {
  content: '\e679';
  color: #444;
}
.icon-loading-medium:before {
  content: '\e67a';
  color: #444;
}
.icon-loading-small:before {
  content: '\e67b';
  color: #444;
}
.icon-variant-mini-icon:before {
  content: '\e676';
  color: #444;
}
.icon-target-corner:before {
  content: '\e677';
  color: #444;
}
.icon-mail:before {
  content: '\e675';
  color: #444;
}
.icon-switch-android:before {
  content: '\e670';
  color: #444;
}
.icon-barcode-ipad:before {
  content: '\e673';
  color: #444;
}
.icon-barcode-android:before {
  content: '\e674';
  color: #444;
}
.icon-settings-android:before {
  content: '\e671';
  color: #444;
}
.icon-bluetooth:before {
  content: '\e672';
  color: #444;
}
.icon-small-shuttle:before {
  content: '\e66f';
  color: #444;
}
.icon-activate-number:before {
  content: '\e66e';
  color: #444;
}
.icon-barcode-scanner:before {
  content: '\e66d';
  color: #444;
}
.icon-profile-loyalty:before {
  content: '\e66c';
  color: #444;
}
.icon-cardpayment-error:before {
  content: '\e668';
  color: #444;
}
.icon-cardpayment-inside:before {
  content: '\e669';
  color: #444;
}
.icon-cardpayment-outside:before {
  content: '\e66a';
  color: #444;
}
.icon-reward:before {
  content: '\e66b';
  /* color: #444; */
}
.icon-cashdrawer:before {
  content: '\e665';
  color: #444;
}
.icon-overpayment:before {
  content: '\e666';
  color: #444;
}
.icon-partial-payment:before {
  content: '\e667';
  color: #444;
}
.icon-connected:before {
  content: '\e656';
  color: #444;
}
.icon-connection-lost:before {
  content: '\e657';
  color: #444;
}
.icon-switch-on:before {
  content: '\e658';
  color: #444;
}
.icon-money:before {
  content: '\e659';
  color: #444;
}
.icon-expanded:before {
  content: '\e65a';
  color: #444;
}
.icon-collapsed:before {
  content: '\e65b';
  color: #444;
}
.icon-expanded-filled:before {
  content: '\e65c';
  color: #444;
}
.icon-collapsed-filled:before {
  content: '\e65d';
  color: #444;
}
.icon-cart-sidebar:before {
  content: '\e65e';
  /* color: #444; */
}
.icon-select:before {
  content: '\e65f';
  color: #444;
}
.icon-deselect:before {
  content: '\e660';
  color: #444;
}
.icon-sidebar-order:before {
  content: '\e661';
  /* color: #444; */
}
.icon-website-bold:before {
  content: '\e662';
  color: #444;
}
.icon-split:before {
  content: '\e663';
  color: #444;
}
.icon-side-signout:before {
  content: '\e664';
  color: #444;
}
.icon-barcode-off:before {
  content: '\e653';
  color: #444;
}
.icon-barcode-on:before {
  content: '\e654';
  color: #444;
}
.icon-mobile-payment:before {
  content: '\e655';
  color: #444;
}
.icon-invoice:before {
  content: '\e652';
  color: #444;
}
.icon-note:before {
  content: '\e64d';
  color: #444;
}
.icon-cashregister:before {
  content: '\e64e';
  color: #444;
}
.icon-billing-info-sidebar:before {
  content: '\e64f';
  color: #444;
}
.icon-quantity:before {
  content: '\e650';
  color: #444;
}
.icon-quantity2:before {
  content: '\e651';
  color: #444;
}
.icon-features-locker:before {
  content: '\e639';
}
.icon-features-integration:before {
  content: '\e63a';
}
.icon-features-cloud:before {
  content: '\e63b';
}
.icon-features-like:before {
  content: '\e63c';
}
.icon-features-sync:before {
  content: '\e63d';
}
.icon-features-sync2:before {
  content: '\e63e';
}
.icon-features-barcode:before {
  content: '\e63f';
}
.icon-features-reports:before {
  content: '\e640';
}
.icon-features-receipt-sms:before {
  content: '\e641';
}
.icon-features-cashorcard:before {
  content: '\e642';
}
.icon-features-print-receipt:before {
  content: '\e643';
}
.icon-features-cashdrawer:before {
  content: '\e644';
}
.icon-features-edit-invoices:before {
  content: '\e645';
}
.icon-features-cashorcard3:before {
  content: '\e646';
}
.icon-features-multipleorder:before {
  content: '\e647';
}
.icon-features-pinemployee:before {
  content: '\e648';
}
.icon-features-tablelayout:before {
  content: '\e649';
}
.icon-features-kitchenreceipt:before {
  content: '\e64a';
}
.icon-features-calendar:before {
  content: '\e64b';
}
.icon-features-more:before {
  content: '\e64c';
}
.icon-cash-refund:before {
  content: '\e637';
}
.icon-card-refund:before {
  content: '\e638';
}
.icon-calculator-small:before {
  content: '\e62d';
}
.icon-price-tag:before {
  content: '\e62e';
}
.icon-profile:before {
  content: '\e62f';
}
.icon-key:before {
  content: '\e630';
}
.icon-username:before {
  content: '\e631';
}
.icon-arrow-up:before {
  content: '\e633';
}
.icon-arrow-down:before {
  content: '\e634';
}
.icon-arrow-left:before {
  content: '\e635';
}
.icon-arrow-right:before {
  content: '\e632';
}
.icon-logo:before {
  content: '\e62c';
}
.icon-no-printer:before {
  content: '\e625';
}
.icon-lock-user:before {
  content: '\e628';
}
.icon-backspace:before {
  content: '\e629';
}
.icon-receipt:before {
  content: '\e62a';
}
.icon-success:before {
  content: '\e62b';
}
.icon-printer:before {
  content: '\e600';
}
.icon-mobile:before {
  content: '\e601';
}
.icon-website:before {
  content: '\e602';
}
.icon-asterisk:before {
  content: '\e603';
}
.icon-order:before {
  content: '\e604';
}
.icon-coffee:before {
  content: '\e605';
}
.icon-trash-bin:before {
  content: '\e606';
}
.icon-dropdown:before {
  content: '\e607';
}
.icon-small-right-arrow:before {
  content: '\e608';
}
.icon-right-arrow:before {
  content: '\e636';
}
.icon-left-arrow:before {
  content: '\e609';
}
.icon-back:before {
  content: '\e60a';
}
.icon-search:before {
  content: '\e60b';
}
.icon-tag:before {
  content: '\e60c';
}
.icon-dot:before {
  content: '\e60d';
}
.icon-filled-dot:before {
  content: '\e60e';
}
.icon-hamburger:before {
  content: '\e60f';
}
.icon-small-close:before {
  content: '\e610';
}
.icon-close:before {
  content: '\e611';
}
.icon-calculator:before {
  content: '\e612';
}
.icon-twitter:before {
  content: '\e613';
}
.icon-facebook:before {
  content: '\e614';
}
.icon-business:before {
  content: '\e615';
}
.icon-password:before {
  content: '\e616';
}
.icon-cart:before {
  content: '\e617';
}
.icon-small-check:before {
  content: '\e618';
}
.icon-check:before {
  content: '\e619';
}
.icon-minus:before {
  content: '\e61a';
}
.icon-plus:before {
  content: '\e61b';
}
.icon-settings-wheel:before {
  content: '\e61c';
}
.icon-settings-sun:before {
  content: '\e61d';
}
.icon-equalize-1:before {
  content: '\e61e';
}
.icon-equalizer-2:before {
  content: '\e61f';
}
.icon-equalizer-3:before {
  content: '\e620';
}
.icon-equalizer-4:before {
  content: '\e621';
}
.icon-report:before {
  content: '\e622';
}
.icon-product:before {
  content: '\e623';
}
.icon-transactions:before {
  content: '\e624';
}
