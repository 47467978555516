.cart-item-input-field {
  width: 170px;
  text-align: center !important;
}
.cart-item-input-field-long {
  width: 300px;
  text-align: center !important;
}

.spacing {
  margin-top: 20px !important;
}

.center {
  text-align: center !important;
}

.cart-item-btn {
  min-width: 0 !important;
  font-size: 16px !important;
}

.addons-btn {
  min-width: 0 !important;
  min-height: 0 !important;
  padding: 0 !important;
  /* margin-left: 15px !important; */
  top: -3px;
}

.bolding {
  font-weight: 600 !important;
  margin-left: 30px !important;
}

.addon-name {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cart-item-discount {
  margin-top: 15px !important;
  max-width: 400px;
}

.discount-select-mode-btn {
  cursor: pointer;
  padding: 12px 18px;
  border: 4px;
  transition: padding 0.3s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  &:active {
    padding: 8px 12px;
  }
}

.discount-select-mode-btn-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.discount-input-select-text {
  margin-top: 10px !important;
  width: 100px;
}

.discount-input-select-adornment {
  padding: 8px 10px 8px 8px;
}

.discount-input-select-adornment-font {
  width: 12px !important;
  font-size: 1rem;
}

.cart-item-quantity {
  width: 300px !important;
  margin-top: 15px !important;
  margin: auto;
}

.input-select-text input {
  text-align: center;
  width: 100px;
}

.input-select-text-note {
  width: calc(100% - 64px) !important;
}

.cart-item-addon-name {
  text-align: left;
  font-size: 0.75rem;
}

.cart-item-addon-list {
  .addon {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
