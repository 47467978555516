.Countr {
  // background-color: #eee;
  height: 100vh;
  /* Prevent to have horizontal scroll bar */
  overflow-x: hidden;
}

.Countr-header {
  background-color: #1d2127;
  padding: 10px;
  color: white;
  /* Fixing top bar */
  position: fixed;
  width: 100%;
  height: 44px;
  z-index: 1001;
  box-shadow: 2px 5px 15px -1px grey;
}

.menuButton {
  position: absolute;
  top: -15px;
  left: 0;
}

.MuiListItemText-multiline {
  margin: 3px !important;
}

.MuiListItemText-root {
  margin-left: 6px !important;
}

.Countr-header-logo {
  height: 40px;
}

.mobile-btn {
  position: absolute !important;
  right: 30px !important;
  top: 8px !important;
  color: #fff !important;
}

.employee-icon {
  width: 120px !important;
  position: absolute !important;
  right: 80px !important;
  border: 1px solid !important;
  padding: 5px;
  border-radius: 5px;
  top: 18px !important;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.employee-shift {
  width: 150px !important;
  position: absolute !important;
  right: 430px !important;
  border: 1px solid !important;
  padding: 5px;
  border-radius: 5px;
  top: 20px !important;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.employee-shift-end {
  width: 150px !important;
  position: absolute !important;
  right: 250px !important;
  border: 1px solid !important;
  padding: 5px;
  border-radius: 5px;
  top: 20px !important;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.employee-shift-end:disabled,
.employee-shift:disabled {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.employee-icon-span {
  font-size: 10px;
  max-width: 100px;
  overflow: hidden;
  line-height: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Root {
  // background-color: #eee;
  text-align: center;
  /* Adjusting where this root component start */
  /* padding: 75px 0px 0px 0px; */
  position: absolute;
  top: 64px;
  height: calc(100% - 64px);
  width: 100%;

  // &.dark {
  //   background-color: #333 !important;
  // }
}

.status {
  position: absolute;
  left: -10px;
  top: -10px;
  z-index: 1002;
  /* background: #36e458; */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid;
}
