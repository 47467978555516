.payment-methods-grid {
  text-align: right;
}

.at-least-one {
  position: relative;
  bottom: 5px;
  left: 15px;
  color: #f44336 !important;
}

.payment-dialog {
  max-width: 700px;
  min-width: 500px;
}

.payments-text {
  text-align: left;
  text-transform: capitalize;
}

.payments-switch-position {
  text-align: right;
}

.payplaza-grid {
  text-align: center;
}

.payplaza-textfield {
  margin: 0 !important;
  position: relative;
  bottom: 10px;
}

.method-name {
  padding: 5px 5px 5px 15px !important;
}
