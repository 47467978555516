.recover-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recover-title-text {
  flex: 1;
}

.m-10 {
  margin: 10px !important;
}

.center {
  text-align: center;
}
