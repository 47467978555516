.search-customer-textfield {
  width: 200px;
}

.customers-div {
  display: inline-flex;
  overflow: hidden;
}

.customer-list {
  width: 100%;
}

.add-new-customer {
  position: absolute !important;
  right: 0;
  top: 10px;
}

.customer-info-header {
  display: inline-flex;
  width: 300px;
}

.customer-info-header-label {
  position: relative;
  top: 15px;
}

.new-customer-switch {
  text-align: center;
}

.mailing_list {
  position: relative;
  top: 15px;
}

.collapse-default {
  max-height: 0px;
  overflow: hidden;
  transform: scaleY(0);
}

.collapse-close {
  animation: closeCollapse 500ms forwards;
}

@keyframes closeCollapse {
  0% {
    max-height: 300px;
    overflow: unset;
  }
  80% {
    max-height: 0px;
    overflow: hidden;
    transform: scaleY(1);
  }
  100% {
    max-height: 0px;
    overflow: hidden;
    transform: scaleY(0);
  }
}

.collapse-open {
  animation: openCollapse 500ms forwards;
}

@keyframes openCollapse {
  0% {
    max-height: 0px;
    overflow: hidden;
    transform: scaleY(0);
  }
  20% {
    transform: scaleY(1);
  }
  100% {
    max-height: 300px;
    overflow: unset;
  }
}

.new-customer-section-header-label {
  font-weight: 600 !important;
}

.new-customer-select-label {
  position: relative;
  top: 25px;
}

.new-customer-select-label-selected {
  font-size: 0.75rem !important;
}

.margin-divider {
  margin: 30px 0px 0px 0px !important;
}

.new-customer-country-align {
  position: relative;
  top: -2px;
}

.country-name {
  margin-left: 20px;
}

.warning-label {
  color: #fa9f47;
}
.warning-border::before {
  border-bottom-color: #fa9f47 !important;
}