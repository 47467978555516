.custom-product {
  min-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin: 8px;

  &:last-child {
    margin-left: 0;
    margin-right: 0;
  }

  .amount {
    flex: 1;
    text-align: center;

    span.title {
      position: relative;
      bottom: 10px;
      padding: 0;
      font-size: 12px;
      line-height: 1;
    }

    .amount-btns {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  .price {
    flex: 1;
  }

  .name {
    width: 100%;
    margin-bottom: 10px;
  }

  .category {
    flex: 1;
    margin-right: 2px;
  }

  .tax {
    flex: 1;
    margin-left: 2px;
  }
}
